<template>
  <div class="item">
    <img :src="'/img/icons/' + successCenterIcons[level] + '.svg'" alt="" />
    <div class="item-info">
      <span class="title">{{ title }}</span>
      <p class="description">{{ description }}</p>
      <span class="link" @click="$router.push(link)">Take Action →</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "stats-card",
  props: {
    level: {
      type: String,
      default: "info",
    },
    title: String,
    description: String,
    link: String,
  },
  data() {
    return {
      successCenterIcons: {
        warning: "warningDiamond",
        urgent: "bell",
        info: "info",
      },
    }
  },
}
</script>
<style lang="scss">
.item {
  display: flex;
  gap: 12px;
  margin-top: 24px;

  img {
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    margin-top: 4px;
  }

  .item-info {
    display: flex;
    flex-direction: column;

    .title {
      font-size: 16px;
    }

    .description {
      color: var(--Gray-800, #3a4252);
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0;
    }

    .link {
      color: var(--gfb-blue-brand-500, #0095e2);
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      cursor: pointer;
      margin-top: 8px;
    }
  }
}
</style>
