<template>
  <div class="dashboard-wrapper">
    <div
      class="profile-completion-status dashboard-content dashboard-component"
      v-if="completed && completed < 75 && showCompletedStatus"
    >
      <div class="close-btn" @click="showCompletedStatus = false">
        <i class="fa fa-times"></i>
      </div>
      <div class="img">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="130"
          height="148"
          viewBox="0 0 130 148"
          fill="none"
        >
          <path
            d="M58.1537 62.3206C72.236 62.3206 84.5496 69.8745 91.2773 81.148C97.6687 73.5931 101.525 63.8245 101.525 53.1538C101.525 29.2007 82.1068 9.78284 58.1537 9.78284C34.2006 9.78284 14.7827 29.2007 14.7827 53.1538C14.7827 63.8245 18.6386 73.5931 25.03 81.148C31.7578 69.8745 44.0714 62.3206 58.1537 62.3206ZM58.1537 21.3591C68.7995 21.3591 77.4297 29.9893 77.4297 40.6351C77.4297 51.2809 68.7995 59.9111 58.1537 59.9111C47.5079 59.9111 38.8777 51.2809 38.8777 40.6351C38.8777 29.9893 47.5079 21.3591 58.1537 21.3591Z"
            fill="#C8ECFF"
          />
          <path
            d="M58.1539 59.9111C68.7997 59.9111 77.4299 51.2809 77.4299 40.6351C77.4299 29.9893 68.7997 21.3591 58.1539 21.3591C47.5081 21.3591 38.8779 29.9893 38.8779 40.6351C38.8779 51.2809 47.5081 59.9111 58.1539 59.9111Z"
            fill="#00527C"
          />
          <path
            d="M58.1539 96.5247C71.4363 96.5247 83.3219 90.5519 91.2776 81.148C84.5498 69.8745 72.2362 62.3206 58.1539 62.3206C44.0716 62.3206 31.758 69.8745 25.0303 81.148C32.986 90.5519 44.8715 96.5247 58.1539 96.5247Z"
            fill="#00527C"
          />
          <path
            d="M95.7418 113.231H20.5655C17.3718 113.231 14.7827 115.82 14.7827 119.014V119.014C14.7827 122.207 17.3718 124.796 20.5655 124.796H95.7418C98.9356 124.796 101.525 122.207 101.525 119.014C101.525 115.82 98.9356 113.231 95.7418 113.231Z"
            fill="#7CD2FF"
          />
          <path
            d="M52.3709 134.434H20.5655C17.3718 134.434 14.7827 137.023 14.7827 140.217V140.217C14.7827 143.411 17.3718 146 20.5655 146H52.3709C55.5646 146 58.1537 143.411 58.1537 140.217C58.1537 137.023 55.5646 134.434 52.3709 134.434Z"
            fill="#C8ECFF"
          />
          <path
            d="M58.1538 102.308C31.0502 102.308 9 80.2573 9 53.1538C9 26.0502 31.0502 4 58.1538 4C85.2573 4 107.308 26.0502 107.308 53.1538C107.308 80.2573 85.2573 102.308 58.1538 102.308ZM58.1538 5.9276C32.1133 5.9276 10.9276 27.1131 10.9276 53.1538C10.9276 79.1945 32.1133 100.38 58.1538 100.38C84.1942 100.38 105.38 79.1945 105.38 53.1538C105.38 27.1131 84.1942 5.9276 58.1538 5.9276Z"
            fill="#003049"
          />
          <g opacity="0.25">
            <path
              d="M122.898 21.8228C124.6 18.2685 123.192 13.9813 119.668 12.153L118.325 11.4558C114.801 9.62743 110.485 10.9444 108.559 14.3832C108.554 14.3831 108.55 14.3827 108.546 14.3825L105.944 19.3959L61.0859 105.846L59.0474 121.251L58.329 126.68C58.2062 127.608 59.3107 128.181 59.9984 127.547L75.4458 113.297L121.251 25.0222L122.799 22.0382C122.799 22.0384 122.8 22.038 122.799 22.0382L122.906 21.8337C122.906 21.8337 122.901 21.8267 122.898 21.8228Z"
              fill="#001F2F"
            />
          </g>
          <path
            d="M110.849 24.2249C110.849 24.2249 119.463 25.1546 125.209 31.6762C127.074 28.0818 125.672 23.6561 122.078 21.791L120.734 21.0938C117.14 19.2287 112.714 20.6306 110.849 24.2249Z"
            fill="#FE6C81"
          />
          <path
            d="M123.661 34.6602L109.301 27.209L63.4954 115.484L77.8553 122.935L123.661 34.6602Z"
            fill="#FFB14A"
          />
          <path
            d="M63.4955 115.484L60.7254 136.417C60.6071 137.311 61.6715 137.864 62.3343 137.252L77.8554 122.935C77.8554 122.935 73.5426 115.207 63.4955 115.484Z"
            fill="#F2D2B1"
          />
          <path
            d="M61.4569 130.889L60.7254 136.417C60.6071 137.311 61.6715 137.864 62.3343 137.252L66.4332 133.471C66.4332 133.471 65.46 129.468 61.4569 130.889Z"
            fill="#0095E2"
          />
          <path
            d="M107.578 30.5287C107.578 30.5287 116.192 31.4583 121.938 37.9799L125.315 31.4717C125.315 31.4717 120.326 24.3101 110.955 24.0205L107.578 30.5287Z"
            fill="#001F2F"
          />
        </svg>
      </div>
      <div class="profile-status-info">
        <p class="text-highlighted">Your profile is almost complete!</p>
        <p class="text-status-info">
          Completing your profile will make it easier to match with employers
          who are looking for your skills and experience!
        </p>
        <b-button
          v-if="!!profile"
          @click="$router.push('/toolbox/update-profile')"
        >
          Complete profile
        </b-button>
      </div>
    </div>
    <div class="pb-5 dashboard-content">
      <!-- <div
        v-if="completed && completed < 75"
        class="d-flex align-items-center w-100"
      >
        <div class="px-3">
          <b-row>
            <h2 class="font-32-900 mb-0 mr-4">
              Your profile is almost complete!
            </h2>
            <b-button
              v-if="!!profile"
              @click="$router.push('/toolbox/update-profile')"
              variant="warning"
              class="rounded-lg"
            >
              Complete profile
            </b-button>
          </b-row>
        </div>
      </div> -->

      <div
        class="dashboard-component stats-wrapper"
        v-if="$store.state.userProfile.type == 'jobSeeker'"
      >
        <div class="stats-inner-wrapper">
          <span class="title">Success Center</span>
          <template v-for="(item, i) in SuccessCenterItems">
            <SuccessCenterItem
              :key="i"
              :level="item.level"
              :title="item.title"
              :link="item.actionLink"
              :description="item.description"
            />
          </template>
        </div>
        <div class="stats-inner-wrapper">
          <StatisticsDisplay
            :stats="opportunitiesCount"
            :title="'Opportunities'"
            icon="opportunities"
            :iconColor="'#1CD47E'"
            :iconBackground="'#B8FADA'"
          />
          <StatisticsDisplay
            :stats="appliedJobsCount"
            :title="'Applied'"
            icon="applied"
            :iconColor="'#0095E2'"
            :iconBackground="'#AFE4FF'"
          />
          <StatisticsDisplay
            :stats="jobMatchesCount"
            :title="'Matches'"
            icon="matches"
            :iconColor="'#F77F00'"
            :iconBackground="'#FFD99B'"
          />
        </div>
      </div>

      <div
        class="dashboard-component"
        v-if="$store.state.userProfile.type == 'jobSeeker'"
      >
        <InterestedCategories :profile="$store.state.userProfile" />
      </div>

      <div
        class="dashboard-component"
        v-if="$store.state.userProfile.type == 'jobSeeker'"
      >
        <JobOpportunities :opportunities="opportunities" />
      </div>

      <div
        class="my-5"
        v-if="
          $store.state.userProfile.type != 'jobSeeker' &&
          !$store.state.userProfile.provider.isVendor
        "
      >
        <div class="row">
          <b-col sm md="12">
            <h1 class="heading-label dark-text mb-0">Your Business Services</h1>
          </b-col>
        </div>
        <div
          class="gfb-radio-container trade-categories-list"
          v-if="tradeCategories.length"
        >
          <label
            v-for="category in tradeCategories"
            :key="category"
            class="trade-category"
            >{{ category }}</label
          >
        </div>
        <div class="row" v-else-if="tradeCategories.length == 0">
          <h2 class="text-center align-items-center" style="margin: auto">
            No Trade categories added in your profile.
          </h2>
        </div>
      </div>

      <div class="my-5" v-if="$store.state.userProfile.type != 'jobSeeker'">
        <div class="row">
          <b-col sm md="12">
            <h1 class="heading-label dark-text mb-0">Employee Skills Needed</h1>
          </b-col>
        </div>
        <div
          class="gfb-radio-container trade-categories-list"
          v-if="employeeSkillsNeeded.length"
        >
          <label
            v-for="skill in employeeSkillsNeeded"
            :key="skill"
            class="trade-category"
            >{{ skill }}
          </label>
        </div>
        <div class="row" v-else-if="employeeSkillsNeeded.length == 0">
          <h2 class="text-center align-items-center" style="margin: auto">
            No employee skills needed added in your profile.
          </h2>
        </div>
      </div>

      <div class="my-5" v-if="$store.state.userProfile.type != 'jobSeeker'">
        <div class="row header-info">
          <b-col sm md="10">
            <h1 class="heading-label dark-text mb-0">
              New Projects in your Area
            </h1>
            <p>Based on the information on your profile</p>
          </b-col>
          <b-col sm md="2" class="see-more-wrapper">
            <DefaultButton
              :labelText="'See more →'"
              @click="$router.push('/trade-partners/search-projects')"
              :disabled="false"
            />
          </b-col>
        </div>
        <div
          class="row"
          style="overflow-x: scroll; flex-wrap: nowrap"
          v-if="projectList.length"
        >
          <div v-for="c in projectList" :key="c._id" class="col-lg-4">
            <project-card :project="c" />
          </div>
        </div>
        <div class="row" v-else-if="projectList.length == 0">
          <div
            v-if="!projectsLoaded"
            class="text-center align-items-center"
            style="margin: auto"
          >
            <b-spinner label="Loading..."></b-spinner>
          </div>
          <h2
            v-else
            class="text-center align-items-center"
            style="margin: auto"
          >
            No New Projects in your Area at the moment. Check back soon!
          </h2>
        </div>
      </div>
    </div>
    <dashboard-profile-modal>
      <candidate-profile-create
        v-if="edit === 'candidate'"
        @close-modal="closeProfileModal"
        :profile="profile"
      />
      <company-profile-create
        v-if="edit && edit !== 'candidate'"
        @close-modal="closeProfileModal"
        :profile="profile"
      />
    </dashboard-profile-modal>
  </div>
</template>
<script>
import DashboardProfileModal from "@/components/Profile/DashboardProfileModal"
import CandidateProfileCreate from "@/components/Profile/Candidate/CandidateProfileCreate"
import CompanyProfileCreate from "@/components/Profile/Company/CompanyProfileCreate"
//import SkilledTrade from "@/components/SkilledTrade"
import ProjectCard from "@/components/Cards/ProjectCard"
import messagesMixin from "@/mixins/messagesMixin"
import DefaultButton from "@/components/DefaultButton"
import StatisticsDisplay from "@/components/StatisticsDisplay"
import JobOpportunities from "@/components/JobOpportunities"
import InterestedCategories from "@/components/InterestedCategories"
import SuccessCenterItem from "@/components/SuccessCenterItem.vue"

export default {
  name: "Dashboard",
  mixins: [messagesMixin],
  components: {
    DashboardProfileModal,
    CandidateProfileCreate,
    CompanyProfileCreate,
    //    SkilledTrade,
    ProjectCard,
    DefaultButton,
    StatisticsDisplay,
    JobOpportunities,
    InterestedCategories,
    SuccessCenterItem,
  },
  data() {
    return {
      completed: "",
      edit: null,
      jobList: this.$store.state.jobs.list,
      jobSeekersList: [],
      projectList: [],
      trainingList: [],
      dataLoaded: false,
      trainingLoaded: false,
      projectsLoaded: false,
      tradeCategories: [],
      employeeSkillsNeeded: [],
      showCompletedStatus: true,
      opportunitiesCount: 0,
      appliedJobsCount: 0,
      jobMatchesCount: 0,
      limit: 100,
      currentPage: 1,
      opportunities: [],
      SuccessCenterItems: [],
    }
  },
  computed: {
    profile() {
      return this.$store.state.userProfile
    },
    profileType() {
      return this.$store.state.profileType
    },
  },
  mounted() {
    if (this.$store.state.userProfile.type == "jobSeeker") {
      this.getFeaturedJobs()
    } else {
      this.getFeaturedJobSeekers()
      this.getFeaturedProjects()
      if (!this.$store.state.userProfile.provider.isVendor) {
        this.tradeCategories =
          this.$store.state.userProfile.selfPerformWorkCategories || []
      }
      this.employeeSkillsNeeded =
        this.$store.state.userProfile.employeeSkillsNeeded || []
    }
  },
  created() {
    this.getProfileCompleted()

    if (this.$store.state.userProfile.type == "jobSeeker") {
      this.getOpportunities()
      this.getAppliedJobs()
      this.getJobMatches()
      this.getSuccessCenterItems()
    }
  },
  methods: {
    async getFeaturedJobs() {
      const profileId = this.$store.state.userProfile?._id
      if (profileId) {
        try {
          /*
          this.$store.dispatch("api/updateProfile", {
            profileId,
            data: { lastActivity: new Date() },
          })
          */
          const data = await this.$store.dispatch("api/searchJobsByParams", {
            zip: this.$store.state.userProfile.zip,
            briefing: false,
            limit: 10,
            page: 1,
          })
          this.jobList = data.data.result
          this.dataLoaded = true
          const data2 = await this.$store.dispatch("api/searchJobsByParams", {
            zip: this.$store.state.userProfile.zip,
            briefing: false,
            willingToProvidePaidTraining: true,
            limit: 10,
            page: 1,
          })
          this.trainingList = data2.data.result
          this.trainingLoaded = true
          //if (data?.completed) this.completed = data.completed
        } catch (error) {
          console.log("error", error)
          this.logDevError(error)
        }
      }
    },
    async getFeaturedJobSeekers() {
      const profileId = this.$store.state.userProfile?._id
      if (profileId) {
        const date = new Date()
        const daysAgo = new Date(date.getTime())
        daysAgo.setDate(date.getDate() - 3)
        //if (zip && position) {
        const zip = this.$store.state.userProfile.zip
        try {
          const list = await this.$store.dispatch("api/searchUsersByParams", {
            filters: {
              zip,
              radius: 50,
            },
            mongoFilters: {
              type: "jobSeeker",
              public: true,
              $or: [
                {
                  "jobSeeker.lastPriorityAccess": {
                    $lt: daysAgo.toISOString(),
                  },
                },
                {
                  $and: [
                    { "jobSeeker.hasHandsOnExperience": false },
                    { "jobSeeker.hasFormalTraining": false },
                  ],
                },
              ],
              // "jobSeeker.hasHandsOnExperience": false,
            },
          })
          this.jobSeekersList = list.data
          this.dataLoaded = true
        } catch (error) {
          this.logDevError(error)
        }
      }
    },
    async getProfileCompleted() {
      const profileId = this.$store.state.userProfile?._id
      const apiUrl =
        this.$store.state.userProfile.type == "jobSeeker"
          ? "api/getProfileCompleted"
          : "api/getCompanyProfileCompleted"
      if (profileId) {
        try {
          const { data } = await this.$store.dispatch(apiUrl, { profileId })
          if (data?.decimal)
            this.completed = Math.ceil(data.decimal * 100).toFixed(0)
        } catch (error) {
          this.logDevError(error)
        }
      }
    },
    async getFeaturedProjects() {
      const list = await this.$store.dispatch("api/searchProjectsByZip", {
        zip: this.$store.state.userProfile.zip,
        // industry: categories,
      })
      //console.log("LIST", list)
      this.projectList = list.data
      this.projectsLoaded = true
    },
    goToJob(id) {
      this.$router.push("/jobs/" + id)
    },
    handleEditProfile() {
      this.$bvModal.show("dashboard-profile-modal")
      this.edit = this.profileType
    },
    closeProfileModal() {
      this.$bvModal.hide("dashboard-profile-modal")
      this.edit = null
    },
    async getOpportunities() {
      const opportunities = await this.$store.dispatch("api/getOpportunities", {
        limit: this.limit,
        page: this.currentPage,
      })
      this.opportunities = opportunities.data.results
      this.opportunitiesCount = opportunities.data.total
    },
    async getAppliedJobs() {
      const applies = await this.$store.dispatch("api/getAppliedJobs", {
        limit: this.limit,
        page: this.currentPage,
      })
      this.appliedJobsCount = applies.data.total
    },
    async getJobMatches() {
      const matches = await this.$store.dispatch("api/getJobMatches", {
        limit: this.limit,
        page: this.currentPage,
      })
      this.jobMatchesCount = matches.data.total
    },
    async getSuccessCenterItems() {
      const result = await this.$store.dispatch("api/getSuccessCenterItems", {
        profileId: this.$store.state.userProfile._id,
      })
      console.log(result)
      this.SuccessCenterItems = result.data
    },
  },
}
</script>
<style lang="scss">
$blue-text-color: var(--gfb-blue-brand-500, #0095e2);

.progress-profile {
  border-radius: 1.375rem;
}
.trade-categories-list {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;

  .trade-category {
    margin-right: 10px;
    margin-top: 10px;
  }
}

.header-info {
  margin-bottom: 20px;
}

.heading-label {
  font-size: 20px;
  font-weight: 700;
  margin-top: revert;
}

.see-more {
  white-space: nowrap;
}

.see-more-wrapper {
  padding: 0;
}

.profile-completion-status {
  display: flex;
  padding: 20px 24px 24px 24px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid $blue-text-color;
  background: var(--White, #fff);
  position: relative;

  .img {
    margin-right: 20px;
  }

  p {
    margin-bottom: 10px;
    line-height: 140%;
  }

  p.text-highlighted {
    color: var(--gfb-blue-brand-700, #00527c);
    font-size: 18px;
    font-weight: 600;
  }

  p.text-status-info {
    color: var(--gfb-blue-brand-700, #00527c);
    font-weight: 400;
  }

  .btn {
    min-width: 112px;
    padding: 8px 16px;
    background: $blue-text-color;
    color: #ffffff;
    margin-top: 15px;

    &:hover {
      background: $blue-text-color !important;
    }
  }

  .close-btn {
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;

    i {
      height: 24px;
      width: 24px;
      font-size: 24px;
      color: #b0b9c9;

      &::before {
        -webkit-text-stroke: 2px white;
      }
    }
  }
}

.dashboard-content {
  margin: 0 40px;
}

.dashboard-component {
  margin-top: 24px;
}

.stats-wrapper {
  display: flex;
  justify-content: space-between;

  .stats-inner-wrapper {
    width: 49%;
    border-radius: 12px;
    background: var(--White, #fff);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    padding: 32px 24px;
    align-items: flex-start;
    align-content: flex-start;
    gap: 8px;
    flex-wrap: wrap;

    .title {
      color: var(--Gray-900, #343946);
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      width: 100%;
    }

    .stats-display {
      width: 100%;
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .stats {
        padding: 45px;
      }

      svg,
      img {
        width: 80%;
        height: 80%;
      }
    }
  }
}
</style>
